import React, { useState, useContext, useEffect } from "react";
import "./User.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Select from 'components/form/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import _ from 'lodash';
import Conf from 'Conf';
import PasswordChange from 'components/User/PasswordChange';

function UserMod({user,handleClose}) {
  const { post } = useContext(ModelContext);
  const [modUser, setModUser] = useState({});
  const valide=!_.isEqual(modUser,user);
  const handleOk=()=>{
    if (valide && user && user.id) post('users',modUser,()=>{
      handleClose();
    });
  }
  const handleChange=(k,v)=>{
    setModUser((state)=>{return{...state,[k]:v}});
  }
  useEffect(()=>{
    if (user) setModUser(user);
  },[user]);
  return <Dialog open={Boolean(user)} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Modifier l'utilisateur</DialogTitle>
      {user && <DialogContent>
        Login : <strong>{modUser.username}</strong>
        <div className="mini-spacer"></div>
        <Text name='Nom' value={modUser.name} handleChange={(v)=>handleChange('name',v)} handleSubmit={handleOk}/>
        <Select name='Role' value={modUser.role} handleChange={(v)=>handleChange('role',v)} options={Conf.roles}/>
        <div className="mini-spacer"></div>
        <PasswordChange user={user}/>
        <div className="mini-spacer"></div>
      </DialogContent>}
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <Button disabled={!valide} onClick={handleOk} variant="contained">Modifier</Button>
      </DialogActions>
    </Dialog>;
}

export default UserMod;
