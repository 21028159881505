import React, {useContext} from "react";
import "./Uploads.scss";
import { SettingsContext } from "providers/SettingsProvider";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CircularProgressWithLabel = React.forwardRef((props,ref)=>{
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }} {...props} ref={ref}>
      <CircularProgress variant="determinate" {...props}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
});

function Uploads() {
  const { uploadProgress,abortUpload } = useContext(SettingsContext);
  const uploads=uploadProgress.filter((p)=>p.percentage<100);
  return uploads.length>0 ? <div className="uploads">
      <div className="uploads-header">
        Upload(s) en cours...
      </div>
      <div className="uploads-list">
      {uploads.map((p)=>{
        return <div key={p.id} className="uploads-list-item">
          <Tooltip title={p.id} PopperProps={{ disablePortal: true }}><CircularProgressWithLabel variant="determinate" value={parseInt(p.percentage)} /></Tooltip>
          <div>
            <IconButton
              size="small"
              color="inherit"
              onClick={()=>abortUpload(p.uploader)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>;
      })}
      </div>
    </div> : '';
}

export default Uploads;
