import React, { useState, useContext } from "react";
import "./User.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Select from 'components/form/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import Conf from 'Conf';

function UserAdd({open,handleClose}) {
  const { post, modele } = useContext(ModelContext);
  const [ addUser, setAddUser] = useState({username:'',name:'',role:'user',password:''});
  const validateLogin=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un login'};
    if (modele.users.findIndex((o)=>o.username===v)!==-1) return {test:false,message:'Ce login n\'est pas disponible'};
    return {test:true};
  }
  const validatePassword=(v)=>{
    if (!v || v.length<8) return {test:false,message:'Au moins 8 caractères'};
    return {test:true};
  }
  const valide=validateLogin(addUser.username).test && validatePassword(addUser.password).test;
  const handleOk=()=>{
    if (valide && addUser) post('users',addUser,()=>{
      handleClose();
      setAddUser({username:'',name:'',role:'user',password:''});
    });
  }
  const handleChange=(k,v)=>{
    setAddUser((state)=>{return{...state,[k]:v}});
  }
  return <Dialog open={Boolean(open)} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Nouvel utilisateur</DialogTitle>
      <DialogContent>
        <div className="mini-spacer"></div>
        <Text name='Nom' value={addUser.name} handleChange={(v)=>handleChange('name',v)} handleSubmit={handleOk}/>
        <Select name='Role' value={addUser.role} handleChange={(v)=>handleChange('role',v)} options={Conf.roles}/>
        <Text name='Login' value={addUser.username} handleChange={(v)=>handleChange('username',v)} validate={validateLogin} handleSubmit={handleOk}/>
        <Text type='password' name='Mot de passe' value={addUser.password} handleChange={(v)=>handleChange('password',v)} validate={validatePassword} handleSubmit={handleOk}/>
        <div className="mini-spacer"></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <Button disabled={!valide} onClick={handleOk} variant="contained">Ajouter</Button>
      </DialogActions>
    </Dialog>;
}

export default UserAdd;
