import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'shared/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from "providers/AuthProvider";
import ModelProvider from "providers/ModelProvider";
import SettingsProvider from "providers/SettingsProvider";
import { BrowserRouter } from "react-router-dom";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import { ThemeProvider } from '@mui/material/styles';
import Utils from 'Utils/Utils';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <AuthProvider>
        <ModelProvider>
          <SettingsProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <ThemeProvider theme={Utils.theme}>
                <App />
              </ThemeProvider>
            </LocalizationProvider>
          </SettingsProvider>
        </ModelProvider>
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  console.log('serviceWorker');
  navigator.serviceWorker
    .register('./service-worker.js', { scope: './' })
    .then(function(registration) {
      console.log("Service Worker Registered");
    })
    .catch(function(err) {
      console.log("Service Worker Failed to Register", err);
    })

}
