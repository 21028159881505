import React, {useContext} from "react";
import './App.scss';

import Login from 'components/Login';
import Home from 'components/Home';
import Me from 'components/User/Me';
import Users from 'components/Users';
import Parcours from 'components/Parcours';
import Parcour from 'components/Parcour';
import Topbar from 'components/Topbar';
import { ModelContext } from "providers/ModelProvider";
import Uploads from 'components/Uploads';
import produce from 'immer';
import { AuthContext } from "providers/AuthProvider";
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function RequireAuth({ children }) {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}
function App() {
  const { auth } = useContext(AuthContext);
  const { messages, setMessages } = useContext(ModelContext);
  const handleCloseSnack=()=>{
    setMessages((state)=>produce(state,(draft)=>{
      draft.splice(0,1);
    }))
  }
  return (
    <>
      {auth.token && <Topbar/>}
      <Routes>
        <Route path="/login" element={<Login />} />
        {auth.role==='admin' && <Route path="/utilisateurs" element={<RequireAuth><Users /></RequireAuth>} />}
        {['user'].indexOf(auth.role)!==-1 && <Route path="/profil" element={<RequireAuth><Me /></RequireAuth>} />}
        <Route path="*" element={<RequireAuth><Home /></RequireAuth>} />
        <Route path="/parcours" element={<RequireAuth><Parcours /></RequireAuth>} />
        <Route path="/parcours/:id" element={<RequireAuth><Parcour /></RequireAuth>} />
      </Routes>
      <div className="bottom-padding"></div>
      <Snackbar
        open={messages && messages.length>0}
        onClose={handleCloseSnack}
        severity="info"
        autoHideDuration={3000}
      >
        {messages.length>0 ? <Alert onClose={handleCloseSnack} severity={messages[0].severity} sx={{ width: '100%' }}>
          {messages[0].text}
        </Alert> : <div/>}
      </Snackbar>
      {auth.token && <Uploads/>}
    </>
  );
}

export default App;
