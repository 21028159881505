import React, {useContext, useState, useEffect, useRef, useCallback, useMemo} from "react";
import "./Home.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import MarkDown from 'components/form/MarkDown';
import _ from 'lodash';
import Upload from 'components/form/Upload';

function Home() {
  const { modele,post } = useContext(ModelContext);
  const [ modSettings, setModSettings ] = useState([]);
  const mounted = useRef(false);
  const cache=useRef(null);
  const settings=useMemo(()=>modele.settings || [],[modele]);
  const handleOk=useCallback(()=>{
    if (modSettings && modSettings !== cache.current) {
      if (cache.current!==null) {
        post('settings',{settings:modSettings});
      }
      cache.current=modSettings;
    }
  },[modSettings,post]);
  const handleChange=useCallback((id,value)=>{
    setModSettings((state)=>{
      const idx=state.findIndex((o)=>o.id===id);
      return[...state.slice(0,idx),{id,value},...state.slice(idx+1)];
    });
  },[setModSettings]);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  },[]);
  useEffect(()=>{
    if (settings) {
      setModSettings(settings);
      cache.current=settings;
    }
  },[settings]);
  const titre=(modSettings.find((o)=>o.id==='titre') || {value:''}).value;
  const soustitre=(modSettings.find((o)=>o.id==='soustitre') || {value:''}).value;
  const edito=(modSettings.find((o)=>o.id==='edito') || {value:''}).value;
  const footer=(modSettings.find((o)=>o.id==='footer') || {value:''}).value;
  const images=(modSettings.find((o)=>o.id==='images') || {value:''}).value;
  return <Container maxWidth="lg">
    <div className="spacer"/>
    <Typography variant="h1">
      Paramètres du site
    </Typography>
    <div className="mini-spacer"></div>
    <Button disabled={_.isEqual(modSettings,settings)} onClick={handleOk} variant="contained">Enregistrer</Button>
    <div className="mini-spacer"></div>
    <Text name='Titre' autoFocus value={titre} handleChange={(v)=>handleChange('titre',v)} handleSubmit={handleOk}/>
    <Text name='Sous-titre' autoFocus value={soustitre} handleChange={(v)=>handleChange('soustitre',v)} handleSubmit={handleOk}/>
    <MarkDown name='Texte' value={edito} handleChange={(v)=>handleChange('edito',v)}/>
    <div className="mini-spacer"></div>
    <MarkDown name='Footer' value={footer} handleChange={(v)=>handleChange('footer',v)}/>
    <div className="mini-spacer"></div>
    <Upload name='Images' value={images || []} type="images" uuid={'settings-images'} handleChange={(v)=>handleChange('images',v)} maxFiles={10}/>
    <div className="mini-spacer"></div>
    <Button disabled={_.isEqual(modSettings,settings)} onClick={handleOk} variant="contained">Enregistrer</Button>
    <div className="mini-spacer"></div>
  </Container>;
}

export default Home;
