import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import "./Parcour.scss";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Blocs from 'components/Blocs';
import Text from 'components/form/Text';
import MarkDown from 'components/form/MarkDown';
import Upload from 'components/form/Upload';
import { ModelContext } from "providers/ModelProvider";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
        <div className="mini-spacer"></div>
        {value === index && children}
        <div className="mini-spacer"></div>
    </div>
  );
}

function ParcourMod({handleClose}) {
  const { modele,post } = useContext(ModelContext);
  const [value, setValue] = useState(0);
  const [modParcour, setModParcour] = useState({});
  const { id } = useParams();
  const mounted = useRef(false);
  const cache=useRef(null);
  const parcour=[...(modele.parcours || [])].find((o)=>o.id===parseInt(id));
  const validateTitre=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un titre'};
    return {test:true};
  }
  const valide=validateTitre(modParcour.titre).test;
  const handleOk=useCallback(()=>{
    if (valide && modParcour && modParcour.id && modParcour !== cache.current) {
      if (cache.current!==null) {
        post('parcours',modParcour);
      }
      cache.current=modParcour;
    }
  },[valide,modParcour,post]);
  const handleChange=(k,v)=>{
    setModParcour((state)=>{return{...state,[k]:v}});
  }
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  },[]);
  useEffect(()=>{
    if (parcour) {
      setModParcour(parcour);
      cache.current=parcour;
    }
  },[parcour]);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(()=>{
    const t=setTimeout(handleOk,10000);
    return ()=>{
      clearTimeout(t);
      if (!mounted.current) handleOk();
    }
  },[modParcour,handleOk]);
  return <Container maxWidth="lg" className="parcour">
    <div className="spacer"/>
    <Typography variant="h1" component="h1">
      Parcours : {modParcour.titre}
    </Typography>
    <div className='spacer'></div>
    {modParcour && <>
        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Infos"/>
          <Tab label="Blocs"/>
        </Tabs>
        <TabPanel value={value} index={0}>
          <Button disabled={_.isEqual(modParcour,parcour)} onClick={handleOk} variant="contained">Enregistrer</Button>
          <div className="mini-spacer"></div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button variant={modParcour.status==="draft" ? "contained" : "outlined"} onClick={()=>handleChange('status','draft')}>Brouillon</Button>
              <Button variant={modParcour.status==="online" ? "contained" : "outlined"} onClick={()=>handleChange('status','online')}>En ligne</Button>
            </ButtonGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text name='Titre' autoFocus value={modParcour.titre || ''} handleChange={(v)=>handleChange('titre',v)} handleSubmit={handleOk} validate={validateTitre}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Upload name='Visuel' value={modParcour.media || []} uuid={'parcours-'+id+'-media'} type="image" handleChange={(v)=>handleChange('media',v)}/>
            </Grid>
            <Grid item xs={12}>
              <MarkDown name='Descriptif' value={modParcour.descriptif || ''} handleChange={(v)=>handleChange('descriptif',v)}/>
            </Grid>
            <Upload name='Images' value={modParcour.images || []} type={'images'} uuid={'parcours-'+id+'-images'} handleChange={(v)=>handleChange('images',v)} maxFiles={10}/>
            <div className="mini-spacer"></div>
          </Grid>
          <div className="mini-spacer"></div>
          <Button disabled={_.isEqual(modParcour,parcour)} onClick={handleOk} variant="contained">Enregistrer</Button>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Blocs idParcours={modParcour.id}/>
        </TabPanel>
        <div className="mini-spacer"></div>
    </>}
    </Container>;
}

export default ParcourMod;
