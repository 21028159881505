import React, {useContext, useState, useCallback, useEffect, useRef} from "react";
import "./Bloc.scss";
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import MarkDown from 'components/form/MarkDown';
import Select from 'components/form/Select';
import Upload from 'components/form/Upload';
import Conf from 'Conf';
import _ from 'lodash';
import Button from '@mui/material/Button';

function Bloc({ id }) {
  const { modele, post} = useContext(ModelContext);
  const bloc=[...(modele.blocs || [])].find((o)=>o.id===parseInt(id));
  const [modBloc, setModBloc] = useState({});
  const mounted = useRef(false);
  const cache=useRef(null);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  },[]);
  useEffect(()=>{
    if (bloc) setModBloc(bloc);
    cache.current=bloc;
  },[bloc]);
  const handleChange=(k,v)=>{
    setModBloc((state)=>{return{...state,[k]:v}});
  }
  const handleOk=useCallback(()=>{
    if (modBloc && modBloc.id && modBloc.id_parcours && modBloc !== cache.current) {
      if (cache.current!==null) {
        post('blocs',modBloc);
      }
      cache.current=modBloc;
    }
  },[modBloc,post]);
  useEffect(()=>{
    const t=setTimeout(handleOk,10000);
    return ()=>{
      clearTimeout(t);
      if (!mounted.current) handleOk();
    }
  },[modBloc,handleOk]);
  return modBloc ? <>
    <Button disabled={_.isEqual(modBloc,bloc)} onClick={handleOk} variant="contained">Enregistrer</Button>
    <div className="mini-spacer"></div>
    <Select name='Type' value={modBloc.type || null} handleChange={(v)=>handleChange('type',v)} options={Conf.types}/>
    <Text name='Titre' autoFocus value={modBloc.titre || ''} handleChange={(v)=>handleChange('titre',v)} handleSubmit={handleOk}/>
    <Upload name='Media' value={modBloc.media || []} type={modBloc.type} uuid={'bloc-'+id+'-media'} handleChange={(v)=>handleChange('media',v)}/>
    <div className="mini-spacer"></div>
    <MarkDown name='Métadonnées du media' value={modBloc.metadata || ''} handleChange={(v)=>handleChange('metadata',v)}/>
    <div className="mini-spacer"></div>
    <MarkDown name='Texte' value={modBloc.texte || ''} handleChange={(v)=>handleChange('texte',v)}/>
    <div className="mini-spacer"></div>
    <Upload name='Images' value={modBloc.images || []} type={'images'} uuid={'bloc-'+id+'-images'} docId={id} handleChange={(v)=>handleChange('images',v)} maxFiles={10}/>
    <div className="mini-spacer"></div>
    <Button disabled={_.isEqual(modBloc,bloc)} onClick={handleOk} variant="contained">Enregistrer</Button>
  </> : '';
}

export default Bloc;
