import React, {useCallback, useContext, useState, useEffect, useRef } from "react";
import "./Upload.scss";
import {useDropzone} from 'react-dropzone'
import * as tus from "tus-js-client";
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import { produce } from 'immer';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import Snackbar from '@mui/material/Snackbar';
import Conf from 'Conf';
import Typography from '@mui/material/Typography';
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import Grid from '@mui/material/Grid';

function Upload({value=[], name='',uuid,maxFiles=1,type='image',volume=1,maxWidth='100%',shadow=true,handleChange}) {
  const mounted = useRef(false);
  const cache = useRef(value);
  const { uploaders, addUploaders } = useContext(SettingsContext);
  const { post } = useContext(ModelContext);
  const [ errors,setErrors ] = useState([]);
  const [ suffix,setSuffix ] = useState(0);
  const [ done,setDone ] = useState(value);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  },[]);
  useEffect(()=>{
    if (cache.current!==value) {
      if (JSON.stringify(done)!==JSON.stringify(value)) {
        setDone(value);
      }
      cache.current=value;
    } else {
      if (JSON.stringify(done)!==JSON.stringify(value)) {
        handleChange(done);
      }
    }
  },[done,value,handleChange,setDone]);
  const addFile=useCallback((f)=>{
    const { name, type, url } = f;
    setDone(state=>[...state,{name, type, url}]);
    if (mounted.current) {
      setTimeout(()=>setSuffix(state=>state+1),1000);
      setTimeout(()=>setSuffix(state=>state+1),5000);
    }
  },[setSuffix,setDone]);
  const handleDelete=useCallback((f)=>{
    setDone(state=>{
      const idx=state.findIndex((o)=>o.url===f.url);
      console.log(state,idx,f)
      if (idx!==-1) {
        console.log([...[...state].splice(0,idx),...[...state].splice(idx+1)])
        return [...[...state].splice(0,idx),...[...state].splice(idx+1)];
      }
      return state;
    });
  },[setDone]);
  const onDrop = useCallback((acceptedFiles) => {
    let newUploaders=[];
    let myUploaders=uploaders.filter((o)=>o.uuid===uuid);
    acceptedFiles.forEach((file, i) => {
      // Create a new tus upload
      if (value.length+myUploaders.length+i<maxFiles) {
        let upload = new tus.Upload(file, {
          endpoint: Conf.apiUrl+'upload',
          onError: (error)=>{
            console.log("Failed because: " + error);
          },
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type
          },
        });
        newUploaders.push(upload);
        // Check if there are any previous uploads to continue.
      } else {
        console.log('Trop de fichiers, nombre max autorisé : '+maxFiles);
        setErrors(errors=>[...errors,'Trop de fichiers, nombre max autorisé : '+maxFiles]);
      }
    });
    addUploaders(newUploaders,uuid,value,addFile);
  },[addUploaders, addFile, maxFiles,uuid,value,uploaders]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const handleCloseSnack=()=>{
    setErrors(produce(errors,(draft)=>{
      draft.splice(0,1);
    }))
  }
  return (Array.isArray(value)) ?
    <div className="input-upload" style={{width:'100%'}}>
      {name && <Typography variant="h5" component="h5" className='input-upload-name'>
        {name}
      </Typography>}
      {value.length<maxFiles && <div className={'upload-drop-zone'+(isDragActive ? ' active' : '')} {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Déposez les fichiers ici, ou cliquez pour selectionner des fichiers</p>
      </div>}
      {type==='images' ? <><Grid container spacing={2}>
        {value.slice(0).reverse().map((file,i)=><Grid key={file.url} item xs={4}>
          <div className="preview-container" style={{maxWidth}}>
            <div className="preview">
              {file.type==='image/svg+xml' ?
              <img src={Conf.filesUrl+file.url} alt="" className={shadow ? 'shadow' : null}/>
              : <img src={Conf.filesUrl+file.url+'-600?'+suffix} alt="" className={shadow ? 'shadow' : null}/>}
            </div>
          </div>
          <div className="name">
            <span>{file.name}</span><br />
            <a href={Conf.filesUrl+file.url} target="_blanc">
              <IconButton
                size="small"
                color="inherit"
              >
                <LinkIcon />
              </IconButton>
            </a>
            <IconButton
              size="small"
              color="inherit"
              onClick={()=>handleDelete(file)}
            >
              <DeleteIcon />
            </IconButton><br />
            <span className="mini">[image:{file.url}]</span>
          </div>
        </Grid>)}
      </Grid>
      <div className="spacer"></div>
      <div className="spacer"></div>
      </> : <>
      {value.slice(0).reverse().map((file,i)=><div key={i}>
        {file && type==='image' ? <>
          <div className="preview-container" style={{maxWidth}}>
            <div className="preview">
              {file.type==='image/svg+xml' ?
              <img src={Conf.filesUrl+file.url} alt="" className={shadow ? 'shadow' : null}/>
              : <img src={Conf.filesUrl+file.url+'-600?'+suffix} alt="" className={shadow ? 'shadow' : null}/>}
            </div>
          </div>
          <div className="name">
          <span>{file.name}</span> <a href={Conf.filesUrl+file.url} target="_blanc">
          <IconButton
            size="small"
            color="inherit"
          >
            <LinkIcon />
          </IconButton>
          </a>
          <IconButton
            size="small"
            color="inherit"
            onClick={()=>handleDelete(file)}
          >
            <DeleteIcon />
          </IconButton>
          </div>
        </>:null}
        {file && type==='audio' ? <>
          <ReactAudioPlayer className="son-preview" src={Conf.filesUrl+file.url} volume={volume} controls/>
          <div className="name">
          <span>{file.name}</span> <a href={Conf.filesUrl+file.url} target="_blanc">
          <IconButton
            size="small"
            color="inherit"
          >
            <LinkIcon />
          </IconButton>
          </a>
          <IconButton
            size="small"
            color="inherit"
            onClick={()=>handleDelete(file)}
          >
            <DeleteIcon />
          </IconButton>
          </div>
        </>:null}
        {file && type==='video' ? <>
          <ReactPlayer url={Conf.filesUrl+file.url} controls/>
          <div className="name">
          <span>{file.name}</span> <a href={Conf.filesUrl+file.url} target="_blanc">
          <IconButton
            size="small"
            color="inherit"
          >
            <LinkIcon />
          </IconButton>
          </a>
          <IconButton
            size="small"
            color="inherit"
            onClick={()=>handleDelete(file)}
          >
            <DeleteIcon />
          </IconButton>
          </div>
        </>:null}
        {file && ['images','image','audio','video'].indexOf(type)===-1 ? <div className="name">
          <span>{file.name}</span> <a href={Conf.filesUrl+file.url} target="_blanc">
          <IconButton
            size="small"
            color="inherit"
          >
            <LinkIcon />
          </IconButton>
          </a>
          <IconButton
            size="small"
            color="inherit"
            onClick={()=>handleDelete(file)}
          >
            <DeleteIcon />
          </IconButton>
          </div>:null}
        </div>
      )}
      </>}
      <Snackbar
        open={errors.length>0}
        onClose={handleCloseSnack}
        message={errors[0]}
        autoHideDuration={5000}
      />
    </div>
  : null;
}

export default Upload;
