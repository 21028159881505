import React from "react";
import "./Select.scss";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';

function Select({name='',value,handleChange,options,multiple=false,validate=()=>true}) {
  return (
    <div className="input-select">
      <FormControl fullWidth>
        <InputLabel>{name}</InputLabel>
        <SelectMui
        value={value===null ? '' : value}
        label={name}
        multiple={multiple}
        onChange={(e)=>handleChange(e.target.value)}
        >
          {options.map((option)=><MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
        </SelectMui>
      </FormControl>
    </div>
  )
}

export default Select;
