import React, {useContext,useEffect} from "react";
import "./Blocs.scss";
import Typography from '@mui/material/Typography';
import Bloc from 'components/Bloc';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { confirm } from 'components/Confirm'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Sortable from 'components/Sortable';

function Blocs({idParcours}) {
  const { modele, post, del } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  useEffect(()=>{
    //console.log('init');
    if (appState && !appState.blocsExpanded) {
      setAppState((state)=>{return {...state,blocsExpanded:{[idParcours]:null}}});
    }
  },[appState,setAppState,idParcours])
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setAppState((state)=>{return {...state,blocsExpanded:{...state.blocsExpanded,[idParcours]: isExpanded ? id : null}}});
  };
  const blocs=[...(modele.blocs || [])].filter((o)=>o.id_parcours===idParcours).sort((a,b)=>a.sort-b.sort);
  const handleAddBloc=()=>{
    if (idParcours) {
      let bloc={titre:'',type:'audio',id_parcours:idParcours};
      post('blocs',bloc);
    }
  }
  const handleDeleteBloc=(bloc,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le bloc ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le bloc ?'
    }).then((ok)=>{
      if (ok) del('blocs',bloc.id);
      else console.log('canceled');
    });
  }
  const handleSort=(list)=>{
    post('blocs|blocsBulk',{blocs:list.filter((o)=>{
      console.log(list,blocs);
      const item=blocs.find((f)=>f.id===o.id);
      return item.sort!==o.sort;
    })});
  }
  return <>
    <Typography variant="h3" component="h3">
      Blocs
    </Typography>
    <Sortable list={blocs} onChange={handleSort} render={(bloc)=>{
      return <Accordion
      key={bloc.id}
      expanded={appState.blocsExpanded && appState.blocsExpanded[idParcours] === bloc.id ? true : false}
      onChange={handleExpandedChange(bloc.id)}
      TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className='sort-handle'
        >
          <Typography>{bloc.titre || bloc.id} <IconButton onClick={(e)=>handleDeleteBloc(bloc,e)}><DeleteIcon/></IconButton></Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Bloc id={bloc.id}/>
        </AccordionDetails>
      </Accordion>
    }}/>
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddBloc}>
      Nouveau bloc
    </Button>
  </>
}

export default Blocs;
