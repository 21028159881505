import React from "react";
import "./MarkDown.scss";
import "shared/MarkDown.scss";
import TextField from '@mui/material/TextField';
import MuiMarkdown from 'mui-markdown';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Conf from 'Conf';

function MarkDown({name='',inputRef=null,endAdornment=undefined,autoFocus=false,value,handleChange,validate=()=>{return {test:true}},type='text',handleBlur=()=>{},disabled=undefined}) {
  const parse=(text)=>{
    return text.replace(/\[image:(.*)\]/gm,'![image]('+Conf.filesUrl+'$1-1000)');
  }
  return (
    <div className={"input-markdown "}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
          multiline
          minRows={4}
          fullWidth
          label={name}
          variant="outlined"
          value={value}
          onChange={(e)=>handleChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} className="markdown-preview">
          <Paper sx={{padding:'15px'}}>
            <MuiMarkdown>{parse(value)}</MuiMarkdown>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default MarkDown;
