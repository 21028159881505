import React, { useState, useContext, useEffect } from "react";
import "./User.scss";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';

function PasswordChange({user,type='users'}) {
  const { post } = useContext(ModelContext);
  const [open, setOpen] = useState(false);
  const [pwd1, setPwd1] = useState('');
  const [pwd2, setPwd2] = useState('');
  const validatePassword=(v)=>{
    if (!v || v.length<8) return {test:false,message:'Au moins 8 caractères'};
    return {test:true};
  }
  const valide=pwd1===pwd2 && validatePassword(pwd1).test;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk=()=>{
    console.log(valide);
    if (valide && user && user.id) {
      console.log(valide);
      post(type,{id:user.id,password:pwd1},()=>{
        handleClose();
      });
    }
  }
  useEffect(()=>{
    setPwd1('');
    setPwd2('');
  },[open])
  return <div>
      <Button size="small" onClick={handleClickOpen}>
        Changer le mot de passe
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle>Changer le mot de passe</DialogTitle>
        <DialogContent>
          <div className="mini-spacer"></div>
          <Text name='Login' value={user.username} disabled/>
          <Text type='password' name='Mot de passe' value={pwd1} handleChange={(v)=>setPwd1(v)} validate={validatePassword} handleSubmit={handleOk}/>
          <Text type='password' name='Vérification' value={pwd2} handleChange={(v)=>setPwd2(v)} handleSubmit={handleOk}/>
          <div className="mini-spacer"></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button disabled={!valide} onClick={handleOk}>Valider</Button>
        </DialogActions>
      </Dialog>
    </div>;
}

export default PasswordChange;
