import React, {useContext, useState} from "react";
import "./Users.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import UserAdd from 'components/User/UserAdd';
import UserMod from 'components/User/UserMod';
import { confirm } from 'components/Confirm'
import Conf from 'Conf';
import Fab from '@mui/material/Fab';

function Users() {
  const { modele,del } = useContext(ModelContext);
  const [ modUser, setModUser ] = useState(null);
  const [ add, setAdd ] = useState(false);
  const users=[...(modele.users || [])].sort((a,b)=>a.username.localeCompare(b.username));
  const handleAddUser=()=>{
    setAdd(true);
  }
  const handleDeleteUser=(user)=>{
    confirm({
      titre: 'Supprimer l\'utilisateur ?',
      confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{user.username}</strong>&nbsp;?</>,
    }).then((ok)=>{
      if (ok) del('users',user.id);
      else console.log('canceled');
    });
  }

  return <>
    <Container maxWidth="lg">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Utilisateurs
      </Typography>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        {users.map((user)=><Grid key={user.id} item xs={12} md={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h4" component="h4" gutterBottom>
                {user.name || 'Sans nom'}
              </Typography>
              <Typography variant="body" gutterBottom>
                {user.username} ({(Conf.roles.find((o)=>o.value===user.role)||{}).label})
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={()=>setModUser(user)}>Modifier</Button>
              {user.username!=='admin' && <Button size="small" onClick={()=>handleDeleteUser(user)}>Supprimer</Button>}
            </CardActions>
          </Card>
        </Grid>)}
      </Grid>
    </Container>
    <div className="floating-action">
      <Container maxWidth="lg">
      <Fab color="primary" aria-label="add" onClick={handleAddUser}>
        <AddIcon />
      </Fab>
      </Container>
    </div>
    <UserAdd open={add} handleClose={()=>setAdd(false)}/>
    <UserMod user={modUser} handleClose={()=>setModUser(null)}/>
  </>
}

export default Users;
