import React from "react";
import "./Text.scss";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

function Text({small=false,clear=false,selectOnFocus=false,name='',inputRef=null,autoFocus=false,value,handleChange,validate=()=>{return {test:true}},type='text',handleBlur=()=>{},handleSubmit=()=>{},disabled=undefined,readOnly=undefined}) {
  return (
    <div className={"input-text"+(validate(value).test ? '' : ' invalid')+(small ? ' small' : '')}>
      <TextField
      fullWidth
      label={name}
      variant="outlined"
      value={value}
      type={type}
      disabled={disabled}
      autoFocus={autoFocus}
      onChange={(e)=>handleChange(e.target.value)}
      onKeyPress={(e)=>e.which===13 && handleSubmit()}
      onBlur={handleBlur}
      inputRef={inputRef}
      size={small ? 'small' : 'medium'}
      onFocus={event => {
        if (selectOnFocus) event.target.select();
      }}
      inputProps={{
        readOnly,
      }}
      InputProps={{
        endAdornment:clear ? <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            edge="end"
            onClick={()=>handleChange('')}
          >
            <CloseIcon/>
          </IconButton>&nbsp;
        </InputAdornment> : null
      }}
      />
      {validate(value).message && <div className="input-text-message">{validate(value).message}</div>}
    </div>
  )
}

export default Text;
