const Conf={
  apiUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://collectages-api.pirena-immateria.org/",
  filesUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"files/" : "https://collectages-api.pirena-immateria.org/files/",
  renewInterval:5*60*1000,
  roles:[
    {label:'Admin',value:'admin'},
    {label:'Redacteur',value:'user'},
  ],
  types:[
    {label:'Audio',value:'audio'},
    {label:'Video',value:'video'},
    {label:'Texte',value:'texte'},
    {label:'Titre',value:'titre'},
  ],
}
export default Conf;
