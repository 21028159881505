import React, { useState, useContext } from "react";
import "./Parcour.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import TextLong from 'components/form/TextLong';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";

function ParcourAdd({open,handleClose}) {
  const { post } = useContext(ModelContext);
  const [ addParcour, setAddParcour] = useState({titre:''});
  const validateTitre=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un titre'};
    return {test:true};
  }
  const valide=validateTitre(addParcour.titre).test;
  const handleOk=()=>{
    if (valide && addParcour) post('parcours',addParcour,()=>{
      handleClose();
      setAddParcour({titre:'', status:'draft'});
    });
  }
  const handleChange=(k,v)=>{
    setAddParcour((state)=>{return{...state,[k]:v}});
  }
  return <Dialog open={Boolean(open)} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Nouveau parcours</DialogTitle>
      <DialogContent>
        <div className="mini-spacer"></div>
        <Text name='Titre' value={addParcour.titre} autoFocus handleChange={(v)=>handleChange('titre',v)} handleSubmit={handleOk} validate={validateTitre}/>
        <TextLong name='Descriptif' value={addParcour.descriptif} handleChange={(v)=>handleChange('descriptif',v)}/>
        <div className="mini-spacer"></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <Button disabled={!valide} onClick={handleOk} variant="contained">Ajouter</Button>
      </DialogActions>
    </Dialog>;
}

export default ParcourAdd;
