import React, {useContext, useState, forwardRef, useCallback} from "react";
import "./Parcours.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ParcourAdd from 'components/Parcour/ParcourAdd';
import { confirm } from 'components/Confirm'
import Fab from '@mui/material/Fab';
import Sortable from 'components/Sortable';
import CheckIcon from '@mui/icons-material/Check';
import {
  useNavigate
} from "react-router-dom";

const CustomGrid = forwardRef((props, ref) => {
  return <Grid container spacing={2} ref={ref}>{props.children}</Grid>;
});


function Parcours() {
  const { modele,del,post } = useContext(ModelContext);
  const [ add, setAdd ] = useState(false);
  const parcours=[...(modele.parcours || [])].sort((a,b)=>a.sort-b.sort);
  const navigate=useNavigate();
  const handleAddParcour=()=>{
    setAdd(true);
  }
  const handleSort=(list)=>{
    post('parcours|parcoursBulk',{parcours:list.filter((o)=>{
      const item=parcours.find((f)=>f.id===o.id);
      return item.sort!==o.sort;
    })});
  }
  const tile=useCallback((parcour)=>{
    const handleDeleteParcour=(parcour)=>{
      confirm({
        titre: 'Supprimer le parcours ?',
        confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{parcour.titre}</strong>&nbsp;?</>,
      }).then((ok)=>{
        if (ok) del('parcours',parcour.id);
        else console.log('canceled');
      });
    };
    return <Grid key={parcour.id} item xs={6} md={2}>
      <Card>
        <CardContent className="sort-handle parcour-content">
          <Typography variant="body">
          {parcour.status==="online" ? <CheckIcon/> : ''} {parcour.titre || 'Sans nom'}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={()=>navigate('/parcours/'+parcour.id)}>Modifier</Button>
          <Button size="small" onClick={()=>handleDeleteParcour(parcour)}>Supprimer</Button>
        </CardActions>
      </Card>
    </Grid>;
  },[navigate,del]);
  return <>
    <Container maxWidth="lg" className="parcours">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Parcours
      </Typography>
      <div className='spacer'></div>
      <Sortable wrapper={CustomGrid} list={parcours} onChange={handleSort} render={tile}/>
    </Container>
    <div className="floating-action">
      <Container maxWidth="lg">
      <Fab color="primary" aria-label="add" onClick={handleAddParcour}>
        <AddIcon />
      </Fab>
      </Container>
    </div>
    <ParcourAdd open={add} handleClose={()=>setAdd(false)}/>
  </>
}

export default Parcours;
