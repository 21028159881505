import React from "react";
import "./TextLong.scss";
import TextField from '@mui/material/TextField';

function TextLong({name='',inputRef=null,endAdornment=undefined,autoFocus=false,value,handleChange,validate=()=>{return {test:true}},type='text',handleBlur=()=>{},disabled=undefined}) {
  return (
    <div className={"input-text"+(validate(value).test ? '' : ' invalid')}>
      <TextField
      fullWidth
      multiline
      minRows={3}
      label={name}
      variant="outlined"
      value={value || ""}
      type={type}
      disabled={disabled}
      autoFocus={autoFocus}
      onChange={(e)=>handleChange(e.target.value)}
      onBlur={handleBlur}
      inputRef={inputRef}
      InputProps={{endAdornment}}
      />
      {validate(value).message && <div className="input-text-message">{validate(value).message}</div>}
    </div>
  )
}

export default TextLong;
