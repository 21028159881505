import React, {useContext,useState} from "react";
import "./Topbar.scss";
import { AuthContext } from "providers/AuthProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
  NavLink,
} from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';

function Topbar() {
  const { auth, onLoggedOut } = useContext(AuthContext);
  const { screenSize } = useContext(SettingsContext);
  const { post } = useContext(ModelContext);
  const [ mobileMenu,setMobileMenu] = useState(false);
  const handlePublish=()=>{
    post('publish',{});
  };
  return (
    <>
    <AppBar position="static" className="topbar" color="primary">
      <Toolbar>
        {['xs','sm','md','lg'].indexOf(screenSize)!==-1 && <>
          <IconButton aria-label="menu" onClick={()=>setMobileMenu(true)} color="inherit">
            <MenuIcon />
          </IconButton>
        </>}
        <Typography variant="h6" component="div" className="topbar-item" sx={{ flexGrow: 1 }}>
          {['xs','sm','md','lg'].indexOf(screenSize)===-1 && <>
            <NavLink to="/">Accueil</NavLink>
            <NavLink to="/parcours">Parcours</NavLink>
            {auth.role==='admin' && <NavLink to="/utilisateurs">Utilisateurs</NavLink>}
            {['user'].indexOf(auth.role)!==-1 && <NavLink to="/profil">Mon profil</NavLink>}
            <Button variant="contained" onClick={handlePublish}>Publier</Button>
          </>}
        </Typography>
        <div className="topbar-user"><small>{auth.name}</small></div>
        <IconButton aria-label="menu" onClick={onLoggedOut} color="inherit">
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Drawer
      anchor='left'
      open={mobileMenu}
      onClose={()=>setMobileMenu(false)}
      className='topbar-drawer'
    >
      <div className='topbar-drawer-header'>
        <IconButton aria-label="menu" onClick={()=>setMobileMenu(false)} color="inherit">
          <MenuIcon />
        </IconButton>
      </div>
      <List onClick={()=>setMobileMenu(false)}>
      <NavLink to="/">
        <ListItem button>
          <ListItemText primary="Accueil" />
        </ListItem>
      </NavLink>
      <NavLink to="/parcours">
        <ListItem button>
          <ListItemText primary="Parcours" />
        </ListItem>
      </NavLink>
      {auth.role==='admin' && <NavLink to="/utilisateurs">
          <ListItem button>
            <ListItemText primary="Utilisateurs" />
          </ListItem>
        </NavLink>}
        {['user'].indexOf(auth.role)!==-1 && <NavLink to="/profil">
          <ListItem button>
            <ListItemText primary="Mon profil" />
          </ListItem>
        </NavLink>}
      </List>
    </Drawer>
    </>
  )
}

export default Topbar;
